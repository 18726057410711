@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  
name the color variables here, reference the variables in the config file
this means that the different themes are defined here since the colors have to be here first
use theme-whatever as a classname variable to change the value of the variables in a given div/section/component

  :root {
    --background1:'#3A0CA3',  
    --background2:'#4361EE',
    --accent1:'#4cc9f0', 
    --accent2:'#F72585',
    --txtColor1:'#3A0CA3', 
    --txtColor2:'#caf0f8',
    --txtColor3:'#071108'

    --color-text-base: PUT THE DIFFERENT COLORS HERE IN EACH THEME
    --color-text-muted: ;
    --color-text-inverted: ;
    --color-fill: ;
    --color-button-accent: ;
    --color-button-accent-hover: ;
    --color-button-muted: ;
  }
  
  .theme-classic //BLUE WHITE AND YELLOW {
    'background1':'#1a64bc', // footer 
    'background2':'#fbfaf3',// ?????
    'accent1':'#f2d850', // client step hover, main div border, navbar shadow
    'accent2':'#f2d850', // client step shadow, navbar hover text
    'txtColor1':'#071108', // Dark Purple
    'txtColor2':'#caf0f8', // light as fuck blue (footer)
    'txtColor3':'#071108', // black
  }

  .theme-neon {
    --color-text-base
    --color-text-muted: ;
    --color-text-inverted: ;
    --color-fill: ;
    --color-button-accent: ;
    --color-button-accent-hover: ;
    --color-button-muted: ;
  }

  .theme-neutral {
    --color-text-base
    --color-text-muted: ;
    --color-text-inverted: ;
    --color-fill: ;
    --color-button-accent: ;
    --color-button-accent-hover: ;
    --color-button-muted: ;
  }

} */
/* 
original = dark purple:'#3A0CA3'
blue/white/yellow = ivory':'#fbfaf3'
beach =  #ffdbac
tron = black */


body {
  background-color: #303030;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
